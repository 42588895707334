import React from 'react'
import Layout from '../components/layout';
import Nav from '../components/nav';
import { ReactComponent as EmptySvg } from '../i/sprites/common/404.svg';
import Button from 'bmc-account-next/lib/js/components/Button';
import UserDataProvider from '../components/userdata-provider/userDataProvider';
import { UserDataContext } from '../components/userdata-provider/userdata-context';
import { isLoggedIn } from 'bmc-account-next/lib/js/server/helpers/user';

const NotFoundPage = ({ location }) => (
  <Layout title="404" location={ location }>
      <UserDataProvider>
        <UserDataContext.Consumer>
          { userData => (
            <Nav location={ location } 
              isTransparent={ false } 
              isLoggedIn={ isLoggedIn(userData) }
              withBackground={ false } 
              userData={ userData } 
            />
          ) }
        </UserDataContext.Consumer>
      </UserDataProvider>

    <div className="error-page">
      <div className="container">
        <div className="wrap">
          <div className="error-page__content">
            <h1 className="error-page__title">Not found</h1>
            <p className="error-page__desc">We have many other wonderful pages, you should look elsewhere.</p>
            <EmptySvg className="svg-404" />
            <Button className="new-button--medium button--new-blue" onClick={ () => { window.history.back(); } }>Go Back</Button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
